import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "claim/data",
  initialState: {
    loading: true,
    enableFreezeColumn: false,
  },
  reducers: {
    setEnableFreezeColumn: (state, action) => {
      state.enableFreezeColumn = action.payload;
    },
  },
});

export const { setEnableFreezeColumn } = dataSlice.actions;

export default dataSlice.reducer;
