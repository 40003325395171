const downloadBase64 = (base64Data, fileName) => {
  const link = document.createElement("a");
  link.href = `data:application/octet-stream;base64,${base64Data}`;
  link.download = fileName;
  link.target = "_blank";
  link.rel = "noopener noreferrer";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadBase64;
