import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "dataPolis/data",
  initialState: {
    loading: true,
    lastFilterValues: {},
    enableFreezeColumn: false,
  },
  reducers: {
    updateLastFilterValues: (state, action) => {
      state.lastFilterValues = action.payload;
    },
    setEnableFreezeColumn: (state, action) => {
      state.enableFreezeColumn = action.payload;
    },
  },
});

export const { updateLastFilterValues, setEnableFreezeColumn } =
  dataSlice.actions;

export default dataSlice.reducer;
