import React, { useState, useEffect } from "react";
import { HiUser } from "react-icons/hi";
import InputChat from "./InputChat";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { get, noop } from "lodash";
import { MdOutlineFileCopy } from "react-icons/md";
import { Notification, Spinner, toast, useConfig } from "components/ui";
import { useLoading } from "components/shared/GlobalLoading";
import { IoMdDownload } from "react-icons/io";
import { getChatList, deleteChat } from "services/ChatService";
import {
  ADMIN,
  BROKER,
  INSURANCE,
  BANK,
  BANK_CABANG,
} from "constants/roles.constant";
import { apiUpdateCounterChat } from "services/NewBusiness";
import { getFileChatClaim } from "services/ClaimService";
import downloadBase64 from "utils/downloadBase64";

const TabByRole = {
  [INSURANCE]: BROKER,
  [BROKER]: INSURANCE,
  [BANK]: INSURANCE,
  [BANK_CABANG]: BROKER,
  [ADMIN]: INSURANCE,
};

const handleDownloadFile = async (fileName) => {
  try {
    const resp = await getFileChatClaim(fileName);
    if (resp.data) {
      downloadBase64(resp.data.data.base64, fileName);
    } else {
      console.log("Failed to get file data from the API response.");
    }
  } catch (e) {
    console.log(e);
  }
};

const ChatBox = ({ id, claimData }) => {
  const userData = useSelector((state) => state.auth.user);
  const userRole = userData.authority[0];
  const [activeTab, setActiveTab] = useState(TabByRole[userRole]);
  const { showLoadingSpinner, hideLoadingSpinner } = useLoading();

  const getCreatedTo = () => {
    switch (activeTab) {
      case INSURANCE:
        return claimData.insurance_id;
      case BROKER:
        return claimData.broker_id;
      case "BPR":
        return claimData.bank_cabang_id;
      default:
        return null;
    }
  };

  const payloadChat = {
    user_login: userData.user,
    created_by: userData.user,
    created_to: getCreatedTo(),
    id_card_number: claimData.id_card_number,
  };

  const handleUpdateCounterChat = async () => {
    try {
      if (claimData?.id_card_number !== undefined) {
        const response = await apiUpdateCounterChat({
          id_card_number: claimData?.id_card_number,
          user_id: userData?.user,
        });

        const result = get(response, "data.data", {});

        return result;
      }

    } catch (error) {
      toast.push(
        <Notification type="danger" duration={2500}>
          {error.message}
        </Notification>,
        {
          placement: "top-end",
        }
      );
    }
  };

  const {
    data: chatData,
    isLoading: loading,
    refetch,
  } = useQuery(
    ["getChatList", payloadChat],
    async ({ queryKey }) => {
      const [, params] = queryKey;
      try {
        const response = await getChatList(params);
        return get(response, "data.data", {});
      } catch (e) {
        return [];
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const fetchChatByTab = async () => {
    await refetch();
  };

  useEffect(() => {
    handleUpdateCounterChat();
    //eslint-disable-next-line
  }, [claimData])

  useEffect(() => {
    fetchChatByTab();
    //eslint-disable-next-line
  }, [activeTab]);

  const handleDelete = async (id) => {
    showLoadingSpinner();
    try {
      await deleteChat(id);
      await refetch();

      toast.push(
        <Notification title="Success" type="success" duration={2500}>
          Berhasil menghapus pesan!
        </Notification>,
        {
          placement: "top-end",
        }
      );
    } catch (error) {
      hideLoadingSpinner();
      let message = get(error, "response.data.message");
      message =
        message || get(error, "response.data.data", "Terjadi kesalahan");

      toast.push(
        <Notification title="Error" type="danger">
          {message}
        </Notification>,
        {
          placement: "top-end",
        }
      );
    }
    hideLoadingSpinner();
  };

  return (
    <div
      className={`${[ADMIN, BROKER].includes(userData.authority[0])
        ? "grid grid-cols-4 gap-4"
        : ""
        } w-full`}
    >
      {[ADMIN, BROKER].includes(userData.authority[0]) && (
        <Sidebar
          claimData={claimData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
      <TabContent
        activeTab={activeTab}
        claimId={id}
        claimData={claimData}
        chatData={chatData}
        userId={userData.user}
        getCreatedTo={getCreatedTo}
        loading={loading}
        handleDelete={handleDelete}
        refetch={refetch}
      />
    </div>
  );
};

const ChatContent = (props) => {
  const {
    activeTab,
    claimData,
    claimId,
    chatData,
    userId,
    createdTo,
    loading,
    handleDelete,
    refetch,
  } = props;
  const { title, description } = getChatHeaderLabel({
    activeTab,
    data: claimData,
  });
  return (
    <div>
      <div className="flex justify-start gap-4 mx-8 pt-5">
        <div className="flex items-center justify-center w-12 h-12 bg-[#D9D9D9] rounded-full">
          <HiUser className="text-2xl text-[#737373]" />
        </div>
        <div>
          <h6>{title}</h6>
          <span>{description}</span>
        </div>
      </div>
      <hr className="my-4" />
      <div className="flex-1 p:2 sm:p-6 justify-between flex flex-col h-screen bg-[#FAFAFA] rounded-md border-2 border-neutral-300">
        <div
          id="messages"
          className="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch"
        >
          {loading ? (
            <Spinner className="mx-auto" size={50} />
          ) : (
            chatData.map((item) => {
              return renderMessage({ item, userId, createdTo, handleDelete });
            })
          )}
        </div>
      </div>
      <div className="mt-4">
        <InputChat
          claim_id={claimId}
          user_id={userId}
          created_to={createdTo}
          idCardNumber={claimData.id_card_number}
          refetch={refetch}
        />
      </div>
    </div>
  );
};

const Sidebar = ({ claimData, activeTab, setActiveTab }) => {
  return (
    <div className="flex-col space-y-4">
      <h6>Chat</h6>
      {[INSURANCE, "BPR"].map((tab) => (
        <SidebarItem
          key={tab}
          tab={tab}
          label={
            tab === "Insurance"
              ? claimData.insurance_name
              : claimData.bank_cabang_name
          }
          description={
            tab === "Insurance" ? "Perusahaan Asuransi" : "Institusi Keuangan"
          }
          activeTab={activeTab === tab ? true : false}
          setActiveTab={setActiveTab}
        />
      ))}
    </div>
  );
};

const SidebarItem = ({ label, tab, description, activeTab, setActiveTab }) => {
  const { themeColor } = useConfig();
  return (
    <div
      onClick={() => setActiveTab(tab)}
      className={`rounded px-3 py-4 cursor-pointer ${activeTab === true
        ? `border-l-4 border-l-${themeColor}-600 bg-${themeColor}-100`
        : "bg-[#FAFAFA] border"
        }`}
    >
      <h6 className="mb-2">{label}</h6>
      <span>{description}</span>
    </div>
  );
};

const TabContent = ({
  activeTab,
  claimId,
  chatData,
  claimData,
  userId,
  handleDelete,
  loading,
  getCreatedTo,
  refetch,
}) => {
  return (
    <div className="col-span-3">
      <ChatContent
        activeTab={activeTab}
        claimData={claimData}
        chatData={chatData}
        claimId={claimId}
        userId={userId}
        createdTo={getCreatedTo()}
        loading={loading}
        handleDelete={handleDelete}
        refetch={refetch}
      />
    </div>
  );
};

const getChatHeaderLabel = ({ activeTab, data }) => {
  switch (activeTab) {
    case INSURANCE:
      return {
        title: data.insurance_name,
        description: "Perusahaan Asuransi",
      };
    case BROKER:
      return {
        title: data.broker_name,
        description: "Perusahaan Broker",
      };
    case "BPR":
      return {
        title: data.bank_cabang_name,
        description: "Institusi Keuangan",
      };
    default:
      return {};
  }
};

const renderMessage = ({ item, userId, createdTo, handleDelete = noop }) => {
  if (item?.created_by?.id === userId) {
    return (
      <div key={`${item.uuid}`} className="chat-message">
        <div className="flex items-end justify-end">
          <div className="flex flex-col space-y-2 text-xs max-w-md mx-2 order-1 items-end">
            {item.file && (
              <div className="flex flex-row items-center gap-2 border border-[#d4d4d4] rounded-[8px] w-full py-4 px-4">
                <MdOutlineFileCopy size={24} />
                <div>
                  <h6 className="font-semibold text-sm">{item.file}</h6>
                  <p>{item.file_size}</p>
                </div>
                <button
                  className="min-w-[44px] h-[32px] flex flex-row justify-center items-center bg-[#e0edfd] rounded-[6px]"
                  onClick={() => handleDownloadFile(item.file)}
                >
                  <IoMdDownload className="text-[#4e84c3]" size={20} />
                </button>
              </div>
            )}
            <div>
              <div className="px-4 py-4 rounded-lg inline-block border border-[#C0DBFB] bg-[#E0EDFD] text-neutral-900 ">
                <div className="mb-4">
                  <h6 className="font-medium">{item.created_by?.name}</h6>
                  <span className="text-[#646464]">
                    {dayjs(item.created_at).format("DD MMMM YYYY HH:mm")}
                  </span>
                </div>
                <div>{item.chat}</div>
                <div className="flex mt-2 text-red-500 justify-end">
                  <span
                    className="cursor-pointer"
                    onClick={() => handleDelete(item.uuid)}
                  >
                    Hapus
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (item?.created_by?.id) {
    return (
      <div className="chat-message">
        <div className="flex items-end">
          <div className="flex flex-col space-y-2 text-xs max-w-md mx-2 order-2 items-start">
            {item.file && (
              <div className="flex flex-row items-center gap-2 border border-[#d4d4d4] rounded-[8px] w-full py-4 px-4">
                <MdOutlineFileCopy size={24} />
                <div>
                  <h6 className="font-semibold text-sm">{item.file}</h6>
                  <p>{item.file_size}</p>
                </div>
                <button
                  className="min-w-[44px] h-[32px] flex flex-row justify-center items-center bg-[#e0edfd] rounded-[6px]"
                  onClick={() => handleDownloadFile(item.file)}
                >
                  <IoMdDownload className="text-[#4e84c3]" size={20} />
                </button>
              </div>
            )}
            <div>
              <div className="px-4 py-4 rounded-lg inline-block bg-neutral-200 text-gray-600">
                <div className="mb-4">
                  <h6 className="font-medium">{item.created_by?.name}</h6>
                  <span className="text-[#646464]">
                    {dayjs(item.created_at).format("DD MMMM YYYY HH:mm")}
                  </span>
                </div>
                {item.chat}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ChatBox;
