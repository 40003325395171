import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/users/login',
        method: 'post',
        data
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/users/register',
        method: 'post',
        data
    })
}

export async function apiSignUpInstansi(data) {
    return ApiService.fetchData({
        url: '/users/register/instansi',
        method: 'post',
        data
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/users/reset_password',
        method: 'post',
        data
    })
}

export async function apiRefreshToken(data) {
    return ApiService.fetchData({
        url: '/users/refresh-token',
        method: 'post',
        data
    })
}
