import {
  Button,
  Dialog,
  FormContainer,
  FormItem,
  Input,
  Notification,
  toast,
} from "components/ui";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { MdOutlineAttachFile } from "react-icons/md";
import { storeChat } from "services/ChatService";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  pesan: Yup.string().required("Kolom pesan wajib diisi!"),
});

const InputChat = ({
  claim_id,
  user_id,
  created_to,
  idCardNumber,
  refetch,
}) => {
  const [dialogIsOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleStoreChat = async (data, setSubmitting, resetForm) => {
    try {
      await storeChat({
        ...data,
        created_by: user_id,
        created_to: created_to,
        id_card_number: idCardNumber,
      });
      refetch();
    } catch (error) {
      toast.push(
        <Notification type="danger" duration={2500}>
          {error.message}
        </Notification>,
        {
          placement: "top-end",
        }
      );
    } finally {
      setSubmitting(false);
      resetForm();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const openDialog = () => {
    setIsOpen(true);
  };

  const onDialogClose = () => {
    setIsOpen(false);
  };

  const onDialogOk = async (resetForm, setSubmitting) => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1];

        const fileName = selectedFile.name;
        const extension = fileName.substring(fileName.lastIndexOf(".") + 1);

        const formData = {
          uuid: claim_id,
          user_id: user_id,
          chat: "",
          file: {
            mime: "." + extension,
            data: base64Data,
          },
        };

        handleStoreChat(formData, setSubmitting, resetForm);
      };
      reader.readAsDataURL(selectedFile);
    }

    setSelectedFile(null);
    setIsOpen(false);
  };

  return (
    <div>
      <Formik
        initialValues={{
          pesan: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          const formData = {
            uuid: claim_id,
            created_by: user_id,
            created_to: created_to,
            id_card_number: idCardNumber,
            chat: values.pesan,
          };
          handleStoreChat(formData, setSubmitting, resetForm);
        }}
      >
        {({
          touched,
          errors,
          setFieldValue,
          isSubmitting,
          resetForm,
          setSubmitting,
        }) => (
          <Form encType="multipart/form-data">
            <FormContainer>
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-9">
                  <FormItem
                    label=""
                    invalid={errors.pesan && touched.pesan}
                    errorMessage={errors.pesan}
                  >
                    <Field
                      type="text"
                      autoComplete="off"
                      name="pesan"
                      placeholder="Masukkan pesan anda disini"
                      component={Input}
                    />
                  </FormItem>
                </div>
                <div className="col-span-1">
                  <Button
                    icon={<MdOutlineAttachFile />}
                    variant="twoTone"
                    className="w-full"
                    onClick={() => openDialog()}
                  />
                </div>
                <Dialog
                  isOpen={dialogIsOpen}
                  onClose={onDialogClose}
                  onRequestClose={onDialogClose}
                >
                  <h5 className="mb-4">Lampirkan File</h5>
                  <input
                    type="file"
                    accept=".xlsx, .xls, .doc, .docx, .pdf, .png, .jpg, .jpeg"
                    onChange={(event) => {
                      const fileInput = event.currentTarget;
                      const file = fileInput.files[0];
                      const maxSize = 1 * 1024 * 1024; // 1MB in bytes

                      if (file && file.size <= maxSize) {
                        handleFileChange(event);
                        setFieldValue("file", file);
                      } else {
                        toast.push(
                          <Notification type="danger" duration={2500}>
                            File lebih dari 1000kb
                          </Notification>,
                          {
                            placement: "top-end",
                          }
                        );
                        fileInput.value = null;
                        setFieldValue("file", null);
                      }
                    }}
                  />
                  <div className="text-right mt-6">
                    <Button
                      className="ltr:mr-2 rtl:ml-2"
                      variant="plain"
                      onClick={onDialogClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="solid"
                      onClick={() => onDialogOk(resetForm, setSubmitting)}
                      disabled={isSubmitting}
                    >
                      Upload
                    </Button>
                  </div>
                </Dialog>
                <div className="col-span-2">
                  <Button
                    type="submit"
                    className="w-full"
                    variant="solid"
                    disabled={isSubmitting}
                  >
                    Kirim
                  </Button>
                </div>
              </div>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InputChat;
