import ApiService from "./ApiService";

export async function apiStoreStaging(data) {
  return ApiService.fetchData({
    url: "/new-business/staging",
    method: "post",
    data
  });
}

export async function apiStoreDatapolis(data) {
  return ApiService.fetchData({
    url: "new-business/datapolis/store",
    method: "post",
    data
  });
}

export async function apiGetUserPayload(userId, projectId) {
  return ApiService.fetchData({
    url: `/configurations/user-payload-newbusiness?upload_by=${userId}&projectId=${projectId}`,
    method: 'GET'
  })
}

export async function apiGetData(data) {
  return ApiService.fetchData({
    url: '/new-business/datapolis',
    method: 'POST',
    data
  })
}

export async function apiGetDetail(uuid) {
  return ApiService.fetchData({
    url: `new-business/datapolis/get-detail/${uuid}`,
    method: 'GET'
  })
}

export async function apiGetPusat(projectId) {
  return ApiService.fetchData({
    url: `/users/filter-pusat?project_id=${projectId}`,
    method: 'GET'
  })
}

export async function apiGetCabang(projectId) {
  return ApiService.fetchData({
    url: `/users/filter-cabang?project_id=${projectId}`,
    method: 'GET'
  })
}

export async function apiUpdateData(data) {
  return ApiService.fetchData({
    url: `/new-business/datapolis/update/${data.uuid}`,
    method: 'PUT',
    data
  })
}

export async function apiUpdateConditionStatus(data) {
  return ApiService.fetchData({
    url: `/new-business/datapolis/update-status-condition/${data.uuid}`,
    method: 'PUT',
    data
  })
}

export async function apiDeleteDatapolis(uuid) {
  return ApiService.fetchData({
    url: `/new-business/datapolis/delete/${uuid}`,
    method: 'DELETE'
  })
}

export async function apiUpdatePremiumRate(data) {
  return ApiService.fetchData({
    url: `/new-business/datapolis/update-status-cbc/${data.uuid}`,
    method: 'PUT',
    data
  })
}

export async function apiUpdateWaitingStatus(data) {
  return ApiService.fetchData({
    url: `/new-business/datapolis/update-waiting-status/${data.uuid}`,
    method: 'PUT',
    data
  })
}

export async function apiGetListDocument(data) {
  return ApiService.fetchData({
    url: `/new-business/datapolis/document/get/${data.uuid}?type=${data.type}`,
    method: 'GET'
  })
}


export async function apiStoreDocument(data) {
  return ApiService.fetchData({
    url: '/new-business/datapolis/document/create',
    method: 'POST',
    data
  })
}

export async function apiUpdateDocument(data) {
  return ApiService.fetchData({
    url: `/new-business/datapolis/document/upload/${data.uuid}`,
    method: 'PUT',
    data
  })
}

export async function apiDeleteDocument(uuid) {
  return ApiService.fetchData({
    url: `/new-business/datapolis/document/delete/${uuid}`,
    method: 'DELETE'
  })
}

export async function apiDownloadDocument(uuid) {
  return ApiService.fetchData({
    url: `/new-business/datapolis/document/get-file/${uuid}`,
    method: 'GET'
  })
}

export async function apiGetDocumentSertif(uuid) {
  return ApiService.fetchData({
    url: `/new-business/datapolis/document/get-sertif/${uuid}`,
    method: 'GET',
    responseType: "arraybuffer"
  })
}

export async function apiManualPenerbitanPolis(uuid) {
  return ApiService.fetchData({
    url: `/new-business/datapolis/submit-policy/manual/${uuid}`,
    method: 'GET'
  })
}

export const getDocumentCertificatePolis = (uuid) => {
  return ApiService.fetchData({
    url: `/new-business/datapolis/document/sertif-polis/${uuid}`,
    method: 'GET'
  })
}

export async function apiKalkulatorPremi(data) {
  return ApiService.fetchData({
    url: '/new-business/kalkulator_premi',
    method: 'POST',
    data
  })
}

export async function apiGetCounterChat(data) {
  return ApiService.fetchData({
    url: '/users/chat/counter',
    method: 'POST',
    data
  })
}

export async function apiUpdateCounterChat(data) {
  return ApiService.fetchData({
    url: '/users/chat/remove-counter',
    method: 'POST',
    data
  })
}

export async function apiDecisionPolis(data) {
  return ApiService.fetchData({
    url: '/new-business/decision_policy',
    method: 'POST',
    data
  })
}