import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "newBusiness/data",
  initialState: {
    counterDataPending: {
      menunggu_dokumen: 0,
      data_ditolak: 0,
      case_by_case: 0,
      bad_data: 0,
      konfirmasi_data: 0
    },
    enableFreezeColumn: true,
  },
  reducers: {
    updateCounterDataPending: (state, action) => {
      state.counterDataPending = action.payload
    },
    updateCounterMenungguDokumen: (state, action) => {
      state.counterDataPending.menunggu_dokumen = action.payload
    },
    updateCounterDataDitolak: (state, action) => {
      state.counterDataPending.data_ditolak = action.payload
    },
    updateCounterCaseByCase: (state, action) => {
      state.counterDataPending.case_by_case = action.payload
    },
    updateCounterBadData: (state, action) => {
      state.counterDataPending.bad_data = action.payload
    },
    updateKonfirmasiData: (state, action) => {
      state.counterDataPending.konfirmasi_data = action.payload
    },
    setEnableFreezeColumn: (state, action) => {
      state.enableFreezeColumn = action.payload;
    },
  },
});

export const {
  updateCounterDataPending,
  updateCounterMenungguDokumen,
  updateCounterDataDitolak,
  updateCounterCaseByCase,
  updateCounterBadData,
  updateKonfirmasiData,
  setEnableFreezeColumn
} = dataSlice.actions;

export default dataSlice.reducer;
