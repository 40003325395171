import axios from "axios";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import { apiSignIn } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import { initialState, setUser } from "store/auth/userSlice";
import { getListRegionalBranch } from "services/ClaimService";
import {
  ADMIN,
  ADMIN_REASURANSI,
  BANK,
  BANK_CABANG,
} from "constants/roles.constant";
import useQuery from "./useQuery";
import { setProjectId } from "store/base/commonSlice";
import { updateLastFilterValues } from "store/dataPolis/dataSlice";
import {
  fetchCentralBranch,
  fetchRegionalBranch,
  fetchInsuranceCompanyList,
} from "utils/common";
import { fetchCounterDataPending } from "utils/counter";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);
  const { lastFilterValues } = useSelector((state) => state.dataPolis.data);

  const signIn = async ({ email, password }) => {
    try {
      const resp = await apiSignIn({ email, password });

      if (resp.data) {
        const { token } = {
          token: resp.data.data.access_token,
        };

        const config = {
          headers: { Authorization: resp.data.data.access_token },
        };

        const getUserByEmail = await axios.get(
          `${appConfig.apiPrefix}/users/profile/user-by-email/${email}`,
          config
        );

        if (getUserByEmail.data) {
          const role = getUserByEmail.data.data.role_name;
          const user = getUserByEmail.data.data.id;
          const projectId = get(getUserByEmail, "data.data.project_uuid");

          const dataUser = {
            refresh_token: resp.data.data.refresh_token,
            email: email,
            userName: getUserByEmail.data.data.fullname,
            authority: [role],
            avatar: getUserByEmail.data.data.path_avatar,
            project_id: getUserByEmail.data.data.project_id,
            project_uuid: getUserByEmail.data.data.project_uuid,
            user_reinsurance: getUserByEmail.data.data.user_reinsurance,
            user,
            isComplete:
              get(getUserByEmail, "data.data.is_complete") === 1 ? true : false,
            ...(await checkIfCentralBranch({
              role,
              user: getUserByEmail.data.data.id,
              projectId,
            })),
            ...(await checkIfRegionalBranch({
              role,
              user: getUserByEmail.data.data.id,
              projectId,
            })),
          };

          checkIfUserReinsurance({
            roles: dataUser.authority,
            dataUser,
          });

          fetchCentralBranch({
            dispatch,
            projectId,
          });

          fetchRegionalBranch({
            dispatch,
            projectId,
          });

          fetchInsuranceCompanyList({
            dispatch,
            projectId,
          });

          fetchCounterDataPending({
            dispatch,
            projectId: getUserByEmail.data.data.project_id,
            projectUUID: getUserByEmail.data.data.project_uuid,
            role,
            user: getUserByEmail.data.data.id,
          });

          dispatch(setProjectId(get(getUserByEmail, "data.data.project_uuid")));
          dispatch(onSignInSuccess(token));
          dispatch(setUser(dataUser));
          dispatch(
            updateLastFilterValues({
              ...lastFilterValues,
              pusat_id: dataUser.pusat_id,
              cabang_id: dataUser.cabang_id,
            })
          );
        }

        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        return {
          status: "success",
          message: resp.data.message,
        };
      }
    } catch (errors) {
      if (errors?.response?.status === 500) {
        return {
          status: "failed",
          message: "Request failed with status code 500"
        };
      }

      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    dispatch({
      type: "RESET_APP",
    });
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    try {
      handleSignOut();
    } catch (errors) {
      handleSignOut();
    }
  };

  const checkIfCentralBranch = async ({ role, user, projectId }) => {
    if (![BANK].includes(role)) {
      return {};
    }

    const response = await getListRegionalBranch({
      project_id: projectId,
      pusat_id: user,
    });
    const result = get(response, "data.data", {});

    return {
      pusat_id: result[0]?.pusat_id,
    };
  };

  const checkIfRegionalBranch = async ({ role, user, projectId }) => {
    if ([BANK].includes(role)) {
      return {
        pusat_id: user,
      };
    }

    if (![BANK_CABANG].includes(role)) {
      return {};
    }

    const response = await getListRegionalBranch({
      project_id: projectId,
      cabang_id: user,
    });
    const result = get(response, "data.data", {});

    return {
      cabang_id: result[0]?.id,
      pusat_id: result[0]?.pusat_id,
    };
  };

  const checkIfUserReinsurance = async ({ roles, dataUser }) => {
    if (roles.includes(ADMIN) && dataUser.user_reinsurance) {
      dataUser.authority = [...roles, ADMIN_REASURANSI];
    } else {
      dataUser.authority = roles;
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
