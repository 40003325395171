import { BANK, BANK_CABANG } from "constants/roles.constant";
import { getListRegionalBranch } from "services/ClaimService";
import { get } from "lodash"
import { getStatistikSecond } from "services/dashboard/AllBusiness";
import { updateCounterDataPending } from "store/newBusiness/dataSlice";

export const fetchCounterDataPending = async ({ dispatch, projectId, projectUUID, role, user }) => {

  const paylod = {
    project_id: projectId,
    pusat_id: null,
    cabang_id: null,
    broker_id: null,
    insurance_id: null,
    start_date: null,
    end_date: null
  }

  if ([BANK_CABANG].includes(role)) {
    const response = await getListRegionalBranch({
      project_id: projectUUID,
      cabang_id: user,
    });

    const result = get(response, "data.data", {});

    paylod.cabang_id = result[0]?.id
    paylod.pusat_id = result[0]?.pusat_id
  }

  if ([BANK].includes(role)) {
    paylod.pusat_id = user
  }

  const fetchCounter = await getStatistikSecond(paylod)

  const resultCounter = get(fetchCounter, "data.data", {});

  dispatch(updateCounterDataPending({ ...resultCounter, konfirmasi_data: resultCounter?.konfirmasi }))

  return resultCounter
}