import ApiService from "../ApiService";

export async function getStatistikFirst(data) {
  return ApiService.fetchData({
    url: "/new-business/dashboard/statistik/first",
    method: "post",
    data
  });
}

export async function getStatistikSecond(data) {
  return ApiService.fetchData({
    url: "/new-business/dashboard/statistik/second",
    method: "post",
    data
  });
}

export async function getStatistikThird(data) {
  return ApiService.fetchData({
    url: "/new-business/dashboard/statistik/third",
    method: "post",
    data
  });
}

export async function getStatistikFourth(data) {
  return ApiService.fetchData({
    url: "/new-business/dashboard/statistik/fourth",
    method: "post",
    data
  });
}

export async function getStatistikAkumulasiPusat(data) {
  return ApiService.fetchData({
    url: "/new-business/dashboard/statistik/akumulasi-premi-pusat",
    method: "post",
    data
  });
}

export async function getStatistikAkumulasiCabang(data) {
  return ApiService.fetchData({
    url: "/new-business/dashboard/statistik/akumulasi-premi-cabang",
    method: "post",
    data
  });
}

export async function getAkumulasiPolisByPeriod(data) {
  return ApiService.fetchData({
    url: "/new-business/dashboard/akumulasi-resiko/polis-by-period",
    method: "post",
    data
  });
}

export async function getAkumulasiMainCreditByPeriod(data) {
  return ApiService.fetchData({
    url: "/new-business/dashboard/akumulasi-resiko/maincredit-by-period",
    method: "post",
    data
  });
}

export async function getAkumulasiNasabah(data) {
  return ApiService.fetchData({
    url: "/new-business/dashboard/akumulasi-resiko/nasabah",
    method: "post",
    data
  });
}

export async function getSebaranPolis(data) {
  return ApiService.fetchData({
    url: "/new-business/dashboard/sebaran-polis",
    method: "post",
    data
  });
}

export async function getPemetaanPolis(data) {
  return ApiService.fetchData({
    url: "/new-business/dashboard/statistik/pemetaan-polis",
    method: "post",
    data
  });
}