import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "debitNote/data",
  initialState: {
    loading: true,
    debitNoteDetail: {},
    debitNoteInsuredDetail: {},
    lastFilterValues: {},
  },
  reducers: {
    updateDebitNoteDetail: (state, action) => {
      state.debitNoteDetail = action.payload;
    },
    updateDebitNoteInsuredDetail: (state, action) => {
      state.debitNoteInsuredDetail = action.payload;
    },
    updateLastFilterValues: (state, action) => {
      state.lastFilterValues = action.payload;
    },
  },
});

export const {
  updateDebitNoteDetail,
  updateDebitNoteInsuredDetail,
  updateLastFilterValues,
} = dataSlice.actions;

export default dataSlice.reducer;
